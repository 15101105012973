html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: inherit;
  // min-width: 100%;
}

button {
  cursor: pointer;
}

.react-player__preview {
  height: 255px !important;
}
